import React from 'react';

const CaseStudyDesign = ({ className, ...delegated }: { className?: string }) => (
  <svg
    className={className}
    {...delegated}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 35.5334H9.47619C9.3499 35.5334 9.2287 35.4832 9.13939 35.3939C9.05009 35.3046 9 35.1835 9 35.0572V5.47619C9 5.3499 9.05009 5.22881 9.13939 5.13951C9.2287 5.05021 9.3499 5 9.47619 5H19C19.1263 5 19.2474 5.05021 19.3367 5.13951C19.426 5.22881 19.4762 5.3499 19.4762 5.47619V35.0572C19.4762 35.1835 19.426 35.3046 19.3367 35.3939C19.2474 35.4832 19.1263 35.5334 19 35.5334ZM10.0095 34.5619H18.5238V5.99051H10.0095V34.5619Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 5.97145H15.0571C14.9447 5.94909 14.8435 5.88842 14.7709 5.79985C14.6982 5.71129 14.6584 5.60029 14.6584 5.48572C14.6584 5.37115 14.6982 5.26016 14.7709 5.1716C14.8435 5.08303 14.9447 5.02236 15.0571 5H19C19.1123 5.02236 19.2134 5.08303 19.2861 5.1716C19.3588 5.26016 19.3985 5.37115 19.3985 5.48572C19.3985 5.60029 19.3588 5.71129 19.2861 5.79985C19.2134 5.88842 19.1123 5.94909 19 5.97145Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 9.67067H15.0571C14.9447 9.64831 14.8435 9.58764 14.7709 9.49907C14.6982 9.4105 14.6584 9.29951 14.6584 9.18494C14.6584 9.07037 14.6982 8.95938 14.7709 8.87081C14.8435 8.78225 14.9447 8.72158 15.0571 8.69922H19C19.1123 8.72158 19.2134 8.78225 19.2861 8.87081C19.3588 8.95938 19.3985 9.07037 19.3985 9.18494C19.3985 9.29951 19.3588 9.4105 19.2861 9.49907C19.2134 9.58764 19.1123 9.64831 19 9.67067Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 13.3582H15.0571C14.9447 13.3358 14.8435 13.2751 14.7709 13.1866C14.6982 13.098 14.6584 12.987 14.6584 12.8724C14.6584 12.7579 14.6982 12.6469 14.7709 12.5583C14.8435 12.4697 14.9447 12.4091 15.0571 12.3867H19C19.1123 12.4091 19.2134 12.4697 19.2861 12.5583C19.3588 12.6469 19.3985 12.7579 19.3985 12.8724C19.3985 12.987 19.3588 13.098 19.2861 13.1866C19.2134 13.2751 19.1123 13.3358 19 13.3582Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 17.0574H15.0571C14.9447 17.035 14.8435 16.9744 14.7709 16.8858C14.6982 16.7972 14.6584 16.6862 14.6584 16.5717C14.6584 16.4571 14.6982 16.3461 14.7709 16.2575C14.8435 16.169 14.9447 16.1083 15.0571 16.0859H19C19.1123 16.1083 19.2134 16.169 19.2861 16.2575C19.3588 16.3461 19.3985 16.4571 19.3985 16.5717C19.3985 16.6862 19.3588 16.7972 19.2861 16.8858C19.2134 16.9744 19.1123 17.035 19 17.0574Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 20.7566H15.0571C14.9447 20.7342 14.8435 20.6736 14.7709 20.585C14.6982 20.4964 14.6584 20.3854 14.6584 20.2709C14.6584 20.1563 14.6982 20.0453 14.7709 19.9568C14.8435 19.8682 14.9447 19.8075 15.0571 19.7852H19C19.1123 19.8075 19.2134 19.8682 19.2861 19.9568C19.3588 20.0453 19.3985 20.1563 19.3985 20.2709C19.3985 20.3854 19.3588 20.4964 19.2861 20.585C19.2134 20.6736 19.1123 20.7342 19 20.7566Z"
      fill="#1A1A1A"
    />
    <path
      d="M19 24.448H15.0571C14.9447 24.4256 14.8435 24.365 14.7709 24.2764C14.6982 24.1878 14.6584 24.0769 14.6584 23.9623C14.6584 23.8477 14.6982 23.7367 14.7709 23.6482C14.8435 23.5596 14.9447 23.4989 15.0571 23.4766H19C19.1123 23.4989 19.2134 23.5596 19.2861 23.6482C19.3588 23.7367 19.3985 23.8477 19.3985 23.9623C19.3985 24.0769 19.3588 24.1878 19.2861 24.2764C19.2134 24.365 19.1123 24.4256 19 24.448Z"
      fill="#1A1A1A"
    />
    <path
      d="M19.0001 28.1433H15.0572C14.931 28.1433 14.8099 28.0931 14.7206 28.0038C14.6313 27.9145 14.5811 27.7934 14.5811 27.6671C14.581 27.539 14.6305 27.4158 14.7193 27.3235C14.8081 27.2311 14.9292 27.1768 15.0572 27.1719H19.0001C19.1281 27.1768 19.2493 27.2311 19.3381 27.3235C19.4269 27.4158 19.4764 27.539 19.4763 27.6671C19.4763 27.7934 19.4261 27.9145 19.3368 28.0038C19.2475 28.0931 19.1264 28.1433 19.0001 28.1433Z"
      fill="#1A1A1A"
    />
    <path
      d="M19.0001 31.8425H15.0572C14.931 31.8425 14.8099 31.7923 14.7206 31.703C14.6313 31.6137 14.5811 31.4926 14.5811 31.3664C14.581 31.2382 14.6305 31.115 14.7193 31.0227C14.8081 30.9303 14.9292 30.876 15.0572 30.8711H19.0001C19.1281 30.876 19.2493 30.9303 19.3381 31.0227C19.4269 31.115 19.4764 31.2382 19.4763 31.3664C19.4763 31.4926 19.4261 31.6137 19.3368 31.703C19.2475 31.7923 19.1264 31.8425 19.0001 31.8425Z"
      fill="#1A1A1A"
    />
    <path
      d="M26.2189 10.4706C26.131 10.4897 26.0401 10.4897 25.9522 10.4706L24.5047 9.49916C24.4485 9.46713 24.3995 9.42405 24.3605 9.37244C24.3216 9.32083 24.2935 9.26178 24.2781 9.19898C24.2627 9.13619 24.2603 9.07093 24.2709 9.00716C24.2815 8.94339 24.3051 8.88251 24.3401 8.82812C24.375 8.77374 24.4206 8.7271 24.4742 8.69094C24.5278 8.65478 24.5882 8.62976 24.6518 8.6177C24.7153 8.60564 24.7806 8.60672 24.8437 8.62072C24.9068 8.63473 24.9664 8.66152 25.0189 8.69931L26.2189 9.4801L27.3999 8.69931C27.4809 8.6525 27.5729 8.6277 27.6666 8.6277C27.7602 8.6277 27.8522 8.6525 27.9332 8.69931L29.1333 9.4801L30.3142 8.69931C30.4203 8.62859 30.55 8.60292 30.6751 8.62793C30.8001 8.65293 30.9101 8.72645 30.9808 8.83254C31.0515 8.93863 31.0772 9.06861 31.0522 9.19364C31.0272 9.31866 30.9536 9.42844 30.8475 9.49916L29.3809 10.4706C29.3034 10.5177 29.2144 10.5427 29.1237 10.5427C29.033 10.5427 28.9441 10.5177 28.8666 10.4706L27.6666 9.67076L26.4856 10.4706C26.3975 10.4876 26.307 10.4876 26.2189 10.4706Z"
      fill="#1A1A1A"
    />
    <path
      d="M30.638 30.6575H24.8285C24.7004 30.6576 24.5772 30.6079 24.4849 30.5191C24.3925 30.4303 24.3382 30.3093 24.3333 30.1813V9.01916C24.3556 8.90679 24.4163 8.80584 24.5048 8.73317C24.5934 8.66049 24.7044 8.62063 24.819 8.62063C24.9335 8.62063 25.0445 8.66049 25.1331 8.73317C25.2217 8.80584 25.2823 8.90679 25.3047 9.01916V29.7051H30.1618V9.01916C30.1618 8.89287 30.212 8.77178 30.3013 8.68248C30.3906 8.59317 30.5117 8.54297 30.638 8.54297C30.7643 8.54297 30.8854 8.59317 30.9747 8.68248C31.064 8.77178 31.1142 8.89287 31.1142 9.01916V30.1813C31.1142 30.3076 31.064 30.4286 30.9747 30.518C30.8854 30.6073 30.7643 30.6575 30.638 30.6575Z"
      fill="#1A1A1A"
    />
    <path
      d="M30.638 35.5316H24.8285C24.7004 35.5317 24.5772 35.482 24.4849 35.3932C24.3925 35.3044 24.3382 35.1834 24.3333 35.0554V30.1793C24.3382 30.0513 24.3925 29.93 24.4849 29.8412C24.5772 29.7524 24.7004 29.703 24.8285 29.7031H30.638C30.7643 29.7031 30.8854 29.7531 30.9747 29.8424C31.064 29.9317 31.1142 30.053 31.1142 30.1793V35.0554C31.1142 35.1817 31.064 35.3028 30.9747 35.3921C30.8854 35.4814 30.7643 35.5316 30.638 35.5316ZM25.3047 34.5601H30.1618V30.7506H25.3047V34.5601Z"
      fill="#1A1A1A"
    />
    <path
      d="M30.6381 9.49781C30.569 9.49723 30.5008 9.48161 30.4383 9.45201C30.3758 9.4224 30.3205 9.37959 30.2762 9.32645L27.7238 6.24075L25.1905 9.32645C25.1505 9.37523 25.1012 9.41575 25.0456 9.4455C24.9899 9.47525 24.929 9.49372 24.8662 9.49991C24.8034 9.5061 24.7401 9.49985 24.6797 9.48154C24.6193 9.46323 24.5631 9.4332 24.5143 9.39318C24.4655 9.35316 24.4251 9.30397 24.3953 9.24833C24.3656 9.19268 24.3471 9.13161 24.3409 9.06882C24.3347 9.00603 24.341 8.94273 24.3593 8.88235C24.3776 8.82197 24.4076 8.76581 24.4477 8.71703L27.362 5.1742C27.41 5.12407 27.4676 5.08403 27.5314 5.05678C27.5952 5.02953 27.6639 5.01562 27.7333 5.01562C27.8027 5.01562 27.8714 5.02953 27.9353 5.05678C27.9991 5.08403 28.0568 5.12407 28.1048 5.1742L31 8.71703C31.0461 8.76145 31.0828 8.8146 31.1078 8.87351C31.1329 8.93242 31.1457 8.99575 31.1457 9.05976C31.1457 9.12376 31.1329 9.18709 31.1078 9.246C31.0828 9.30491 31.0461 9.35829 31 9.40272C30.8935 9.47418 30.766 9.50759 30.6381 9.49781Z"
      fill="#1A1A1A"
    />
    <path
      d="M27.7047 28.1412C27.5766 28.1413 27.4534 28.0917 27.3611 28.0029C27.2687 27.9141 27.2144 27.7931 27.2095 27.665V12.1793C27.2144 12.0513 27.2687 11.9303 27.3611 11.8415C27.4534 11.7527 27.5766 11.703 27.7047 11.7031C27.831 11.7031 27.9521 11.7533 28.0414 11.8426C28.1307 11.9319 28.1809 12.053 28.1809 12.1793V27.665C28.1809 27.7913 28.1307 27.9124 28.0414 28.0017C27.9521 28.091 27.831 28.1412 27.7047 28.1412Z"
      fill="#1A1A1A"
    />
    <path
      d="M30.638 32.5227H24.8285C24.7022 32.5227 24.581 32.4725 24.4917 32.3832C24.4024 32.2939 24.3523 32.1728 24.3523 32.0465C24.3523 31.9202 24.4024 31.7991 24.4917 31.7098C24.581 31.6205 24.7022 31.5703 24.8285 31.5703H30.638C30.7643 31.5703 30.8854 31.6205 30.9747 31.7098C31.064 31.7991 31.1142 31.9202 31.1142 32.0465C31.1142 32.1728 31.064 32.2939 30.9747 32.3832C30.8854 32.4725 30.7643 32.5227 30.638 32.5227Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CaseStudyDesign;
